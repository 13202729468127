@import '../../../shared/styles/variable';

.error-boundary {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  & svg {
    fill: @primary-color;
    width: 100%;
    max-width: 400px;
  }
}
@primary-color: #008484;