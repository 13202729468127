@import '../../../../../shared/styles/variable';
@import '../../../../../shared/styles/mixin';

.cr-user-info {
  background-color: transparent;
  padding: 7px 12px;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .transition(all .2s ease);

  @media screen and (min-width: @screen-md) {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 71px;
  }

  & .ant-dropdown-link {
    color: inherit;

    & .anticon {
      font-size: @font-size-sm;
    }
  }

  &.light {
    & .ant-dropdown-link {
      color: inherit;
    }

    & .cr-user-name {
      color: inherit;
    }
  }
}

.cr-user-info-inner {
  display: flex;
  align-items: center;
}

.cr-user-info-avatar {
  font-size: 24px;
  background-color: @orange-6;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cr-user-info-content {
  width: calc(100% - 62px);
  margin-left: 16px;
  .transition(all .2s ease);

  [dir=rtl] & {
    margin-left: 0;
    margin-right: 16px;
  }
}

.cr-user-name-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cr-user-name {
  margin-bottom: 0;
  font-size: @font-size-lg;
  font-weight: @font-weight-medium;
  color: inherit;

  &.light {
    color: inherit;
  }
}

.cr-user-arrow {
  margin-left: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  [dir=rtl] & {
    margin-left: 0;
    margin-right: 12px;
  }

  & svg {
    display: block;
  }
}

.cr-user-designation {
  margin-top: -2px;
  color: inherit;

  .ant-layout-sider-dark & {
    color: inherit;
  }
}

.header-dropdown-list {
  background-color: white;
  border: 1px solid #b4b4b4;
  & .ant-list-item {
    padding: 5px 12px;
    cursor: pointer;

    &:hover {
      background-color: darken(@component-background, 3);
    }
  }
}

.cr-user-info-hasColor {

  & .ant-dropdown-link {
    color: inherit;
  }

  &.light {
    & .ant-dropdown-link {
      color: inherit;
    }

    & .cr-user-name {
      color: inherit;
    }
  }

  & .cr-user-designation {
    color: inherit;

    .ant-layout-sider-dark & {
      color: inherit;
    }
  }

  & .cr-user-name {
    color: inherit;

    &.light {
      color: inherit;
    }
  }
}
@primary-color: #008484;